import { useEffect } from 'react'
import { notifyManager, type QueryKey, useQueryClient } from '@tanstack/react-query'
import { useIsOffline } from '@yms/common/components/Offline/useIsOffline'

import { useYardZonesDetailsQuery } from '@mobile/api/yard-zones/useYardZonesDetailsQuery'
import { useCurrentHandlerTypeQuery } from '@mobile/auth/hooks/useCurrentHandlerTypeQuery'
import { useCurrentOperatorQuery } from '@mobile/auth/hooks/useCurrentOperatorQuery'

import { prefetchWorker } from './client/prefetch'

interface QueryResultMessage {
  type: 'query-result'
  data: {
    queryKey: QueryKey
    queryData: unknown
  }
}

interface QueryBatchMessage {
  type: 'query-batch'
  data: {
    queryKey: QueryKey
    queryData: unknown
  }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isQueryResultMessage = (message: any): message is QueryResultMessage => {
  return (
    message &&
    message.type === 'query-result' &&
    message.data &&
    Array.isArray(message.data.queryKey) &&
    typeof message.data.queryData !== 'undefined'
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isQueryBatchMessage = (message: any): message is QueryBatchMessage => {
  return message && message.type === 'query-batch' && Array.isArray(message.data)
}

export const PrefetchProvider = () => {
  const queryClient = useQueryClient()

  const location = useYardZonesDetailsQuery()
  const handlerType = useCurrentHandlerTypeQuery()
  const operatorId = useCurrentOperatorQuery({ select: data => data.operatorId })
  // Sync changing user context to the worker
  useEffect(() => {
    prefetchWorker.postMessage({
      type: 'userContext',
      payload: { location, handlerType, operatorId }
    })
  }, [handlerType, location, operatorId])

  const isOffline = useIsOffline()
  // Send a flag to the worker to stop trying to prefetch
  // Otherwise it'll just go forever (no reason to do this)
  useEffect(() => {
    prefetchWorker.postMessage({
      type: 'isOffline',
      payload: { isOffline }
    })
  }, [isOffline])

  // Legacy handler for setting batches of queries
  useEffect(() => {
    prefetchWorker.onmessage = event => {
      console.debug('Message received from worker', event.data)

      if (isQueryResultMessage(event.data)) {
        queryClient.setQueryData(event.data.data.queryKey, event.data.data.queryData)
        notifyManager.batch(() => {})
      } else if (isQueryBatchMessage(event.data)) {
        for (const message of event.data.data) {
          // queryClient.setQueryData(message.queryKey, message.queryData)
          const query = queryClient.getQueryCache().build(queryClient, {
            queryKey: message.queryKey
          })

          query.setData(message.queryData)
        }
      }
    }
  }, [handlerType, location, operatorId, queryClient])

  return null
}
