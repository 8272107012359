import { Suspense, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslate } from '@yms/hexagon/hooks/useTranslate'
import { tableManager } from '@yms/common/components/Table/migrations/manager'
import { useGetCountQuery } from '@yms/common/components/Table/hooks/useGetCountQuery'

import { MenuItem } from '@mobile/core/Layout/Menu/Menu.item'
import { currentOperator } from '@mobile/auth/hooks/useCurrentOperatorQuery'
import { useYardZonesDetailsQuery } from '@mobile/api/yard-zones/useYardZonesDetailsQuery'
import { useCurrentHandlerTypeQuery } from '@mobile/auth/hooks/useCurrentHandlerTypeQuery'

import { defaultView, TABLE_NAME } from './Tasks.config'
import { getCountFetcher } from './Tasks.fetcher'

// eslint-disable-next-line react-refresh/only-export-components
function TasksMenuItem() {
  const t = useTranslate()

  const location = useYardZonesDetailsQuery()
  const handlerType = useCurrentHandlerTypeQuery()
  const userContext = useMemo(() => ({ location, handlerType }), [handlerType, location])

  const operatorQuery = useQuery({
    queryKey: currentOperator.queryKey,
    queryFn: currentOperator.queryFn
  })

  const [table] = useMemo(() => {
    return tableManager.parse(operatorQuery.data?.tables[TABLE_NAME])
  }, [operatorQuery.data?.tables])

  const view = useMemo(() => {
    if (!table) return defaultView

    const view = table.views.find(view => view.id === table.preferredView)
    return view ?? defaultView
  }, [table])

  const countQuery = useGetCountQuery({
    fetcher: getCountFetcher,
    state: view.dirtyState ?? view,
    userContext,
    tableName: TABLE_NAME
  })

  return (
    <MenuItem path="/tasks" icon="swap-horizontal" title={t('tasks')} badge={countQuery.data} />
  )
}

// eslint-disable-next-line react-refresh/only-export-components
function SuspendedTasksMenuItem() {
  const t = useTranslate()

  return (
    <Suspense fallback={<MenuItem path="/tasks" icon="swap-horizontal" title={t('tasks')} />}>
      <TasksMenuItem />
    </Suspense>
  )
}

export const config = {
  Item: SuspendedTasksMenuItem,
  key: 'tasks'
}
