export const MessageTypes = {
  DetailsResponse: 'detailsResponse',
  QueryBatch: 'query-batch',
  Setup: 'setup',
  FetchTable: 'fetchTable',
  Unload: 'unload',
  UserContext: 'userContext',
  Stop: 'stop',
  StopRefetch: 'stopRefetch',
  GetByQueryKey: 'getByQueryKey',
  IsOffline: 'isOffline'
} as const
