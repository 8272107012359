import {
  type PaginationState,
  type ColumnOrderState,
  type ColumnPinningState,
  type SortingState,
  type VisibilityState
} from '@tanstack/react-table'
import { type Draft, produce } from 'immer'

import { type TableSettings, type Filtering, type TableView } from '../Table.types'

// Note: Added specifically for migration purposes
export interface TableViewV0 {
  id: string
  name: string
  sorting: SortingState
  columnOrder: ColumnOrderState
  columnVisibility: VisibilityState
  columnPinning: ColumnPinningState
  filtering: Filtering
  isProtected?: boolean
  count?: number
  dirty: boolean
  dirtyState?: Omit<TableView, 'dirtyState'>
}

export interface TableSettingsV0 {
  search?: string
  selectedView?: string
  views: TableViewV0[]
  pagination?: PaginationState
  version?: number
}

export const upgradeToV1 = (tableSettings: TableSettingsV0): TableSettings => {
  if (!tableSettings.version || tableSettings.version === 0) {
    return produce<TableSettingsV0, Draft<TableSettings>>(tableSettings, draft => {
      draft.version = 1
      for (let i = 0; i < draft.views.length; i++) {
        const view = draft.views[i]
        view.order = i + 1
        if (view.dirtyState) {
          view.dirtyState.order = i + 1
        }
      }
    }) as TableSettings
  }

  if (tableSettings.version === 1) {
    return tableSettings as TableSettings
  }

  throw new Error('Invalid table version' + tableSettings.version)
}
