import { QFilterExpression } from '@odata2ts/odata-query-builder'
import { type QDateTimeOffsetPath } from '@odata2ts/odata-query-objects'
import { QYardTaskProjection } from '@yms/api/odata-client/QItgPltYmsApi'
import { arrayToEqualsFilter } from '@yms/api/odataUtilities'
import { type UserContext, type Filter } from '@yms/common/components/Table/Table.types'
import dayjs from 'dayjs'

export const pushLocationFilter = (
  location: UserContext['location'],
  filters: QFilterExpression[]
) => {
  const yardTask = new QYardTaskProjection()
  let locationsFilter
  for (const [siteCode, yardZoneNames] of location) {
    let yardZoneFilter = null
    for (const yardZone of yardZoneNames) {
      const filter = new QFilterExpression(`search.ismatch('"${yardZone}"')`)
      yardZoneFilter = yardZoneFilter ? yardZoneFilter.or(filter) : filter
    }

    const locationFilter = yardTask.siteCode.eq(siteCode).and(yardZoneFilter)
    locationsFilter = locationsFilter ? locationsFilter.or(locationFilter) : locationFilter
  }

  filters.push(locationsFilter!)
}

export const pushFinishedOnFilter = (
  property: QDateTimeOffsetPath,
  filter: Filter,
  filters: QFilterExpression[]
) => {
  if (filter.name === 'finishedOn') {
    filters.push(
      property.ge(
        dayjs
          .utc()
          .startOf('day')
          .subtract(dayjs.duration(filter.value[0] as string))
          .toISOString()
      )
    )
  }
}

export const pushYardFromFilter = (
  filter: Filter,
  filters: QFilterExpression[],
  contextYardZones: string[],
  yardTask: QYardTaskProjection
) => {
  if (filter.name === 'yardFrom') {
    const filtered = filter.value.filter(yz => contextYardZones.includes(yz as string)) as string[]
    filters.push(
      arrayToEqualsFilter(yardTask.fromLocationDetails.getEntity(true).yardZone, filtered)
    )
  }
}

export const pushYardToFilter = (
  filter: Filter,
  filters: QFilterExpression[],
  contextYardZones: string[],
  yardTask: QYardTaskProjection
) => {
  if (filter.name === 'yardTo') {
    const filtered = filter.value.filter(yz => contextYardZones.includes(yz as string)) as string[]

    filters.push(
      arrayToEqualsFilter(yardTask.plannedToLocationDetails.getEntity(true).yardZone, filtered)
        .or(
          arrayToEqualsFilter(
            yardTask.alternativePlannedToLocationDetails.getEntity(true).yardZone,
            filtered
          )
        )
        .or(
          arrayToEqualsFilter(
            yardTask.operatorsPlannedToLocationDetails.getEntity(true).yardZone,
            filtered
          )
        )
        .or(
          arrayToEqualsFilter(yardTask.actualToLocationDetails.getEntity(true).yardZone, filtered)
        )
    )
  }
}
