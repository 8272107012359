import type HxStatusChip from '@katoennatie/hexagon/status-chip'
import { createColumnHelper } from '@tanstack/react-table'
import { type YardTaskProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'
import { ContentStatus, MoveType, YardTaskStatus } from '@yms/api/client/v1/data-contracts'
import { Text } from '@yms/hexagon'
import { type TableView } from '@yms/common/components/Table/Table.types'
import { ChipCell } from '@yms/common/components/Table/Cells/Table.ChipCell'
import {
  type FilteringOption,
  type SimpleOption
} from '@yms/common/components/Table/Table.filtering.types'
import { FlexCell } from '@yms/common/components/Table/Cells/FlexCell'

import { DynamicFilter } from './filters'

export const taskStatusChipMapping: Record<string, HxStatusChip['variant']> = {
  [YardTaskStatus.Planned]: 'info',
  [YardTaskStatus.Finished]: 'success',
  [YardTaskStatus.Busy]: 'error',
  [YardTaskStatus.Queued]: 'info',
  [YardTaskStatus.Cancelled]: 'archive'
}

const column = createColumnHelper<YardTaskProjection>()
export const columns = [
  column.accessor('status', {
    id: 'status',
    enableMultiSort: true,
    cell: ChipCell,
    meta: {
      title: 'taskStatus',
      chipMapping: taskStatusChipMapping
    }
  }),
  column.display({
    id: 'type',
    cell: ({ row }) => (
      <Text variant="body" color="base" size="sm">
        {row.original.shippingUnitContentStatus?.[0]}
        {row.original.moveType?.[0]}
      </Text>
    ),
    meta: {
      title: 'type'
    }
  }),
  column.accessor('shippingUnitNumber', {
    id: 'shippingUnitNumber',
    enableMultiSort: true,
    meta: {
      title: 'shippingUnit'
    }
  }),
  column.accessor('queuedOn', {
    id: 'queuedOn',
    enableMultiSort: true,
    meta: {
      title: 'queuedOn',
      localDateTime: true
    }
  }),
  column.display({
    id: 'fromLocations',
    enableSorting: false,
    meta: {
      title: 'from'
    },
    cell: ({ row }) => (
      <FlexCell>
        <Text variant="body" color="base" size="sm">
          {row.original.fromLocationDetails?.slots?.[0] ??
            row.original.fromLocationDetails?.block ??
            row.original.fromLocationDetails?.yardZone ??
            row.original.fromLocations?.[0]}
        </Text>
        {(row.original.fromLocationDetails?.slots.length ?? 0) > 1 && (
          <Text bold>+{(row.original.fromLocationDetails?.slots.length ?? 1) - 1}</Text>
        )}
      </FlexCell>
    )
  }),
  column.display({
    id: 'finalToLocation',
    enableSorting: false,
    meta: {
      title: 'to'
    },
    cell: ({ row }) => {
      if (row.original.moveType === MoveType.Out) {
        return (
          <Text variant="body" color="base" size="sm">
            {row.original.actualToLocations?.[0] ?? row.original.plannedToLocation}
          </Text>
        )
      }

      if (row.original.status === YardTaskStatus.Finished) {
        return (
          <FlexCell>
            <Text variant="body" color="base" size="sm">
              {row.original.actualToLocationDetails?.slots?.[0] ??
                row.original.actualToLocationDetails?.block ??
                row.original.actualToLocationDetails?.yardZone}
            </Text>
            {(row.original.actualToLocationDetails?.slots.length ?? 0) > 1 && (
              <Text bold>+{(row.original.actualToLocationDetails?.slots.length ?? 1) - 1}</Text>
            )}
          </FlexCell>
        )
      }

      if (row.original.finalToLocation) {
        return (
          <Text variant="body" color="base" size="sm">
            {row.original.finalToLocationDetails?.slot ??
              row.original.finalToLocationDetails?.block ??
              row.original.finalToLocationDetails?.yardZone}
          </Text>
        )
      }

      return (
        <Text variant="body" color="base" size="sm">
          {row.original.plannedToLocationDetails?.slot ??
            row.original.plannedToLocationDetails?.block ??
            row.original.plannedToLocationDetails?.yardZone}
        </Text>
      )
    }
  }),
  column.accessor('customerCode', {
    id: 'customerCode',
    enableMultiSort: true,
    meta: {
      title: 'customer'
    }
  }),
  column.accessor('customerMainReference', {
    id: 'customerMainReference',
    enableMultiSort: true,
    meta: {
      title: 'customerMainReference'
    }
  }),
  column.display({
    id: 'lotBatch',
    cell: props => {
      const cargos = props.row.original.cargos
      const lotBatch = cargos.length > 1 ? 'Mixed' : cargos?.[0]?.lotBatch
      return (
        <Text variant="body" color="base" size="sm">
          {lotBatch}
        </Text>
      )
    },
    meta: {
      title: 'lotBatch'
    }
  }),
  column.accessor('parentOrderGroup', {
    id: 'parentOrderGroup',
    enableMultiSort: true,
    meta: {
      title: 'suWithSameParent'
    }
  }),
  column.accessor('shippingUnitKind', {
    id: 'shippingUnitKind',
    enableMultiSort: true,
    meta: {
      title: 'shippingUnitKind'
    }
  }),
  column.accessor('transportCompany', {
    id: 'transportCompany',
    enableMultiSort: true,
    meta: {
      title: 'transportCompany'
    }
  }),
  column.accessor('finishBefore', {
    id: 'finishBefore',
    enableMultiSort: true,
    meta: {
      title: 'finishBefore',
      localDateTime: true
    }
  }),
  column.accessor('closingOrDetentionDate', {
    id: 'closingOrDetentionDate',
    enableMultiSort: true,
    meta: {
      title: 'closingOrDetentionDate',
      localDateTime: true
    }
  }),
  column.accessor('loadingUnloadingPlannedAt', {
    id: 'loadingUnloadingPlannedAt',
    enableMultiSort: true,
    meta: {
      title: 'unLoadingPlannedDate',
      localDateTime: true
    }
  }),
  column.display({
    id: 'article',
    cell: props => {
      const cargos = props.row.original.cargos
      const article = cargos.length > 1 ? 'Mixed' : cargos?.[0]?.article
      return (
        <Text variant="body" color="base" size="sm">
          {article}
        </Text>
      )
    },
    meta: {
      title: 'article'
    }
  }),
  column.accessor('finishedOn', {
    id: 'finishedOn',
    enableMultiSort: true,
    meta: {
      title: 'finishedOn',
      datetime: true
    }
  }),
  column.accessor('finishedBy', {
    id: 'finishedBy',
    enableMultiSort: true,
    meta: {
      title: 'finishedBy'
    }
  }),
  column.accessor('vessel', {
    id: 'vessel',
    enableMultiSort: true,
    meta: {
      title: 'vessel'
    }
  }),
  column.display({
    id: 'configuration',
    cell: props => {
      const cargos = props.row.original.cargos
      const configuration = cargos.length > 1 ? 'Mixed' : cargos?.[0]?.configuration
      return (
        <Text variant="body" color="base" size="sm">
          {configuration}
        </Text>
      )
    },
    meta: {
      title: 'configuration'
    }
  }),
  column.display({
    id: 'alternativePlannedToLocation',
    enableSorting: false,
    meta: {
      title: 'alternativeToLocation'
    },
    cell: ({ row }) => (
      <Text variant="body" color="base" size="sm">
        {row.original.alternativePlannedToLocationDetails?.slot ??
          row.original.alternativePlannedToLocationDetails?.block ??
          row.original.alternativePlannedToLocationDetails?.yardZone}
      </Text>
    )
  })
]

export const TABLE_NAME = 'tasks'

export const defaultView: TableView = {
  id: 'default',
  order: 0,
  name: 'Default',
  dirty: false,
  isProtected: true,
  columnOrder: [],
  columnVisibility: {},
  sorting: [{ id: 'queuedOn', desc: true }],
  columnPinning: {},
  filtering: [
    { type: 'equals', name: 'status', value: [YardTaskStatus.Queued, YardTaskStatus.Finished] }
  ]
}

const enumToOption = <T,>(value: T) => ({
  label: value,
  value
})

export const filteringOptions: FilteringOption[] = [
  {
    name: 'status',
    label: 'taskStatus',
    type: 'checkbox',
    options: [YardTaskStatus.Queued, YardTaskStatus.Finished].map(enumToOption)
  },
  {
    name: 'shippingUnitContentStatus',
    label: 'content',
    type: 'checkbox',
    options: [ContentStatus.Full, ContentStatus.Empty].map(enumToOption)
  },
  {
    name: 'moveType',
    label: 'moveType',
    type: 'checkbox',
    options: [MoveType.In, MoveType.Out, MoveType.Shift].map(enumToOption)
  },
  {
    name: 'yardFrom',
    label: 'yardFrom',
    type: 'dynamic',
    resolvedType: 'checkbox',
    render: DynamicFilter.Yard
  },
  {
    name: 'yardTo',
    label: 'yardTo',
    type: 'dynamic',
    resolvedType: 'checkbox',
    render: DynamicFilter.Yard
  },
  {
    name: 'finishedBy',
    label: 'finishedBy',
    type: 'dynamic',
    resolvedType: 'checkbox',
    render: DynamicFilter.FinishedBy,
    visible: filtering => !!(filtering.status as SimpleOption)?.includes(YardTaskStatus.Finished)
  },
  {
    name: 'finishedOn',
    label: 'finishedOn',
    type: 'radio',
    visible: filtering => !!(filtering.status as SimpleOption)?.includes(YardTaskStatus.Finished),
    options: [
      { label: 'last:x:days', labelProps: { x: 3 }, value: 'P3D' },
      {
        label: 'last:x:days',
        labelProps: { x: 7 },
        value: 'P7D'
      },
      {
        label: 'lastMonth',
        value: 'P1M'
      }
    ]
  },
  {
    name: 'isUrgent',
    label: 'urgent',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ]
  }
]
