import { redirect } from 'react-router-dom'
import { getIsOffline } from '@yms/common/components/Offline/getIsOffline'

import { userManager } from '@mobile/auth/userManager'
import { queryClient } from '@mobile/App.queryClient'

import { currentOperator } from '../hooks/useCurrentOperatorQuery'

export const authGuard = async () => {
  const user = await userManager.getUser()

  if (!user || user.expired) {
    return redirect('/auth')
  }

  if (!getIsOffline()) {
    try {
      const operatorQueryData = await queryClient.fetchQuery({
        retry: false,
        queryKey: currentOperator.queryKey,
        queryFn: currentOperator.queryFn
      })

      if (!operatorQueryData || !operatorQueryData.permissions.viewYardOverview) {
        return redirect('/403')
      }
    } catch (error) {
      if (error instanceof Response) {
        if (error.status === 403) {
          // Note: If query fails with 403 status, it means that user doesn't have permissions set
          return redirect('/403')
        }
      }
    }
  }
}
