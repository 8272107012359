import { type ReactNode } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslate } from '@yms/common/hooks/useTranslate'
import { OfflineBanner } from '@yms/common/components/Offline/OfflineBanner'
import { Badge } from '@yms/hexagon'

import { Menu } from '../Layout/Menu'
import styles from './MobileLayout.module.css'
import { MobileLogo } from './MobileLogo'

type MenuItem = {
  Item: () => ReactNode
  key: string
}

const menuItems: MenuItem[] = Object.values(
  import.meta.glob('@mobile/modules/**/pages/**/*.menu-item.tsx', {
    eager: true,
    import: 'config'
  })
)

type LayoutProps = {
  readonly children?: ReactNode
}

function DefaultMenu() {
  const t = useTranslate()
  const fetchingCount = useIsFetching()

  return (
    <Menu>
      <div className={clsx(styles.logo, styles.disabled)}>
        <Badge value={fetchingCount > 0 ? undefined : 0}>
          <MobileLogo />
        </Badge>
      </div>
      {menuItems.map(({ Item, key }) => (
        <Item key={key} />
      ))}
      <Menu.Item path="/shipping-units" icon="train-car-container" title={t('shippingUnits')} />
      <Menu.Item path="/yard" icon="map-marker-multiple" title={t('yard')} />
      <Menu.Item path="/more" icon="dots-vertical" title={t('more')} />
    </Menu>
  )
}

export function MobileLayout({ children }: LayoutProps) {
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.outletWrapper}>
        <OfflineBanner />
        {children}
      </div>
      <DefaultMenu />
    </div>
  )
}
