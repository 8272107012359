import { type TableSettings } from '../Table.types'
import { isTableSettings } from '../utils/isTableSettings'
import { type TableSettingsV0, upgradeToV1 } from './from-v0-to-v1'

export const tableManager = {
  hasUpgrade: (tableSettings: TableSettingsV0 | TableSettings) => {
    return tableSettings.version !== 1
  },
  upgrade: (tableSettings: TableSettingsV0 | TableSettings) => {
    if (!tableSettings.version) {
      return upgradeToV1(tableSettings as TableSettingsV0)
    }

    return tableSettings as TableSettings
  },
  parse: (tableSettings?: string): [TableSettings, null] | [null, Error] => {
    if (!tableSettings) {
      return [null, new Error('Table settings are missing')]
    }

    try {
      const tableSettingsParsed = JSON.parse(tableSettings)
      if (isTableSettings(tableSettingsParsed)) {
        return [tableSettingsParsed, null]
      } else {
        return [null, new Error('Invalid table settings')]
      }
    } catch (error) {
      return [null, error as Error]
    }
  },
  getPreferredView: (tableSettings: TableSettings) => {
    return tableSettings.views.find(view => view.id === tableSettings.preferredView)
  }
}
