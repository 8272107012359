import { type QueryKey } from '@tanstack/react-query'

import { MessageTypes } from '../shared/messageTypes'

export const prefetchWorker = new Worker(
  new URL('./../worker/prefetch.worker.ts', import.meta.url),
  {
    type: 'module'
  }
)

export const prefetch = {
  stop: () => {
    prefetchWorker.postMessage({ type: 'stop' })
  },
  stopRefetch: () => {
    prefetchWorker.postMessage({ type: 'stopRefetch' })
  },
  requestDetails: (queryKey: QueryKey) => {
    prefetchWorker.postMessage({
      type: MessageTypes.GetByQueryKey,
      payload: { queryKey: JSON.stringify(queryKey) }
    })
  }
}
