import { useEffect, useState } from 'react'
import { Button } from '@yms/hexagon'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { info, error as logError } from '@yms/common/logging'
import { snackbar } from '@yms/common/utils/snackbar'
import { dispatchAction } from '@yms/common/Actions'
import { type Dialog } from '@yms/common/providers/DialogProvider'

export function ReloadPrompt() {
  const [loading, setLoading] = useState(false)
  const {
    offlineReady: [offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegistered() {
      info('Service worker has been registered')
    },
    onRegisterError(error) {
      logError('Service worker registration error', error)
    }
  })

  useEffect(() => {
    if (offlineReady) {
      snackbar.info('applicationDownloaded')
    }
  }, [offlineReady])

  useEffect(() => {
    if (needRefresh) {
      dispatchAction<Dialog>('dialog:open', {
        type: 'normal',
        id: 'new-version-available',
        title: 'newVersionAvailable',
        message: 'newVersionAvailable.message',
        content: (
          <>
            <Button
              data-testid="close"
              slot="secondary-button"
              onClick={() => {
                dispatchAction('dialog:close', 'new-version-available')
              }}
            >
              close
            </Button>
            <Button
              slot="primary-button"
              data-testid="update"
              loading={loading}
              onClick={() => {
                setLoading(true)
                updateServiceWorker(true)
                setTimeout(window.location.reload, 5000)
              }}
            >
              update
            </Button>
          </>
        )
      })
    }
  }, [loading, needRefresh, updateServiceWorker])

  return null
}
