/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOperatorPayload,
  CurrentOperatorDto,
  GetODataOperatorsParams,
  HttpValidationProblemDetails,
  ODataOperatorDtoArrayODataCollectionResponse,
  OperatorDetailsDto,
  ProblemDetails,
  SaveSessionPayload,
  SetPinCodePayload,
  UpdateOperatorPayload,
  UpsertOperatorViewPayload
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Operators<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags Operators
   * @name UpsertOperatorView
   * @summary Upsert an operator view for the current logged-in user.
   * @request PUT:/api/v1/operators/current/table-settings/{tableName}
   * @secure
   */
  upsertOperatorView = (
    tableName: string,
    data: UpsertOperatorViewPayload,
    params: RequestParams = {}
  ) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/current/table-settings/${tableName}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name UpdateOperator
   * @request PUT:/api/v1/operators/{id}
   * @secure
   */
  updateOperator = (id: string, data: UpdateOperatorPayload, params: RequestParams = {}) =>
    this.http.request<
      OperatorDetailsDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name GetOperatorById
   * @request GET:/api/v1/operators/{id}
   * @secure
   */
  getOperatorById = (id: string, params: RequestParams = {}) =>
    this.http.request<
      OperatorDetailsDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name SetPinCode
   * @request PATCH:/api/v1/operators/{id}/set-pin-code
   * @secure
   */
  setPinCode = (id: string, data: SetPinCodePayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/${id}/set-pin-code`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name SaveSession
   * @summary Saves the operator's chosen handler and yard zones from their last mobile app session.
   * @request PUT:/api/v1/operators/current
   * @secure
   */
  saveSession = (data: SaveSessionPayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/current`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name GetCurrentOperator
   * @summary Gets the operator's chosen handler and yard zones from their last mobile app session.
   * @request GET:/api/v1/operators/current
   * @secure
   */
  getCurrentOperator = (params: RequestParams = {}) =>
    this.http.request<
      CurrentOperatorDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/current`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name GetODataOperators
   * @request GET:/api/v1/operators
   * @secure
   */
  getODataOperators = (query: GetODataOperatorsParams, params: RequestParams = {}) =>
    this.http.request<ODataOperatorDtoArrayODataCollectionResponse, HttpValidationProblemDetails>({
      path: `/api/v1/operators`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name CreateOperator
   * @request POST:/api/v1/operators
   * @secure
   */
  createOperator = (data: CreateOperatorPayload, params: RequestParams = {}) =>
    this.http.request<OperatorDetailsDto, HttpValidationProblemDetails>({
      path: `/api/v1/operators`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name DeactivateOperator
   * @request PATCH:/api/v1/operators/{id}/deactivate
   * @secure
   */
  deactivateOperator = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/${id}/deactivate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
  /**
   * No description
   *
   * @tags Operators
   * @name ActivateOperator
   * @request PATCH:/api/v1/operators/{id}/activate
   * @secure
   */
  activateOperator = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/operators/${id}/activate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
}
