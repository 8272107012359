// @ts-nocheck
import {
  QueryObject,
  QStringPath,
  QCollectionPath,
  QStringCollection,
  QEntityPath,
  QBooleanPath,
  QDateTimeOffsetPath,
  QEntityCollectionPath,
  QId,
  QStringParam,
  QNumberPath,
  QGuidPath,
  QGuidParam,
  QEnumPath,
  QBinaryPath
} from '@odata2ts/odata-query-objects'

import {
  type YardTaskProjectionId,
  type YardOrderProjectionId,
  type NotificationSubscriptionProjectionId,
  type RuleProjectionId,
  type ODataSiteDtoId,
  type ODataYardZoneDtoId,
  type ODataShippingUnitKindDtoId,
  type ODataBlockDtoId,
  type ODataHandlerDtoId,
  type ODataOperatorDtoId,
  type ODataSlotDtoId,
  type ODataCustomerDtoId,
  type ODataShippingLineDtoId,
  type ODataTransportCompanyDtoId,
  type ODataYardVisitExternalIdDtoId,
  type ODataYardTaskFinishContextDtoId,
  type ODataOperationalSiteDtoId,
  type ODataOperationalYardZoneDtoId,
  type ODataOperationalBlockDtoId,
  type ODataOperationalSlotDtoId,
  type ODataSwapEligibleContainerDtoId,
  type OperationalOrderProjectionId
} from './ItgPltYmsApiModel'

export class QYardTaskProjection extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly yardOrderId = new QStringPath(this.withPrefix('yardOrderId'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly shippingUnitNumber = new QStringPath(this.withPrefix('shippingUnitNumber'))
  public readonly shippingUnitKind = new QStringPath(this.withPrefix('shippingUnitKind'))
  public readonly shippingUnitStatus = new QStringPath(this.withPrefix('shippingUnitStatus'))
  public readonly status = new QStringPath(this.withPrefix('status'))
  public readonly shippingUnitContentStatus = new QStringPath(
    this.withPrefix('shippingUnitContentStatus')
  )
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly moveType = new QStringPath(this.withPrefix('moveType'))
  public readonly handlerType = new QStringPath(this.withPrefix('handlerType'))
  public readonly fromLocations = new QCollectionPath(
    this.withPrefix('fromLocations'),
    () => QStringCollection
  )
  public readonly fromLocationDetails = new QEntityPath(
    this.withPrefix('fromLocationDetails'),
    () => QShippingUnitLocationProjection
  )
  public readonly plannedToLocation = new QStringPath(this.withPrefix('plannedToLocation'))
  public readonly plannedToLocationDetails = new QEntityPath(
    this.withPrefix('plannedToLocationDetails'),
    () => QYardLocationProjection
  )
  public readonly alternativePlannedToLocation = new QStringPath(
    this.withPrefix('alternativePlannedToLocation')
  )
  public readonly alternativePlannedToLocationDetails = new QEntityPath(
    this.withPrefix('alternativePlannedToLocationDetails'),
    () => QYardLocationProjection
  )
  public readonly operatorsPlannedToLocation = new QStringPath(
    this.withPrefix('operatorsPlannedToLocation')
  )
  public readonly operatorsPlannedToLocationDetails = new QEntityPath(
    this.withPrefix('operatorsPlannedToLocationDetails'),
    () => QYardLocationProjection
  )
  public readonly actualToLocations = new QCollectionPath(
    this.withPrefix('actualToLocations'),
    () => QStringCollection
  )
  public readonly actualToLocationDetails = new QEntityPath(
    this.withPrefix('actualToLocationDetails'),
    () => QShippingUnitLocationProjection
  )
  public readonly finalToLocation = new QStringPath(this.withPrefix('finalToLocation'))
  public readonly finalToLocationDetails = new QEntityPath(
    this.withPrefix('finalToLocationDetails'),
    () => QYardLocationProjection
  )
  public readonly isUrgent = new QBooleanPath(this.withPrefix('isUrgent'))
  public readonly customerCode = new QStringPath(this.withPrefix('customerCode'))
  public readonly transportCompany = new QStringPath(this.withPrefix('transportCompany'))
  public readonly licensePlateTruck = new QStringPath(this.withPrefix('licensePlateTruck'))
  public readonly licensePlateTrailer = new QStringPath(this.withPrefix('licensePlateTrailer'))
  public readonly queuedOn = new QDateTimeOffsetPath(this.withPrefix('queuedOn'))
  public readonly meansOfTransport = new QStringPath(this.withPrefix('meansOfTransport'))
  public readonly cargos = new QEntityCollectionPath(
    this.withPrefix('cargos'),
    () => QYardTaskCargoProjection
  )
  public readonly finishBefore = new QDateTimeOffsetPath(this.withPrefix('finishBefore'))
  public readonly createdBy = new QStringPath(this.withPrefix('createdBy'))
  public readonly createdAt = new QDateTimeOffsetPath(this.withPrefix('createdAt'))
  public readonly queuedBy = new QStringPath(this.withPrefix('queuedBy'))
  public readonly finishedBy = new QStringPath(this.withPrefix('finishedBy'))
  public readonly finishedOn = new QDateTimeOffsetPath(this.withPrefix('finishedOn'))
  public readonly finishingRemark = new QStringPath(this.withPrefix('finishingRemark'))
  public readonly remark = new QStringPath(this.withPrefix('remark'))
  public readonly operationalOrderIdentifier = new QStringPath(
    this.withPrefix('operationalOrderIdentifier')
  )
  public readonly operationalOrderParentIdentifier = new QStringPath(
    this.withPrefix('operationalOrderParentIdentifier')
  )
  public readonly customerReferences = new QStringPath(this.withPrefix('customerReferences'))
  public readonly customerMainReference = new QStringPath(this.withPrefix('customerMainReference'))
  public readonly processingErrors = new QEntityCollectionPath(
    this.withPrefix('processingErrors'),
    () => QProcessingErrorProjection
  )
  public readonly hasQueueErrors = new QBooleanPath(this.withPrefix('hasQueueErrors'))
  public readonly parentOrderGroup = new QStringPath(this.withPrefix('parentOrderGroup'))
  public readonly checkedInOutTime = new QDateTimeOffsetPath(this.withPrefix('checkedInOutTime'))
  public readonly vessel = new QStringPath(this.withPrefix('vessel'))
  public readonly detentionDateCustomerKtn = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerKtn')
  )
  public readonly detentionDateCustomerShippingLine = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerShippingLine')
  )
  public readonly closingDate = new QDateTimeOffsetPath(this.withPrefix('closingDate'))
  public readonly closingOrDetentionDate = new QDateTimeOffsetPath(
    this.withPrefix('closingOrDetentionDate')
  )
  public readonly loadingUnloadingPlannedAt = new QDateTimeOffsetPath(
    this.withPrefix('loadingUnloadingPlannedAt')
  )
  public readonly isLoadingUnloadingFinished = new QBooleanPath(
    this.withPrefix('isLoadingUnloadingFinished')
  )
  public readonly operationalOrderType = new QStringPath(this.withPrefix('operationalOrderType'))
  public readonly shippingLine = new QStringPath(this.withPrefix('shippingLine'))
  public readonly returnAddress = new QStringPath(this.withPrefix('returnAddress'))
  public readonly alternativeReturnAddress = new QStringPath(
    this.withPrefix('alternativeReturnAddress')
  )
  public readonly isSwapAllowed = new QBooleanPath(this.withPrefix('isSwapAllowed'))
  public readonly isRequeueAllowed = new QBooleanPath(this.withPrefix('isRequeueAllowed'))
  public readonly shippingUnitIsRejected = new QBooleanPath(
    this.withPrefix('shippingUnitIsRejected')
  )
  public readonly shippingUnitRejectReason = new QStringPath(
    this.withPrefix('shippingUnitRejectReason')
  )
}

export const qYardTaskProjection = new QYardTaskProjection()

export class QYardTaskProjectionId extends QId<YardTaskProjectionId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class QYardOrderProjection extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly shippingUnitId = new QStringPath(this.withPrefix('shippingUnitId'))
  public readonly status = new QStringPath(this.withPrefix('status'))
  public readonly number = new QStringPath(this.withPrefix('number'))
  public readonly contentStatus = new QStringPath(this.withPrefix('contentStatus'))
  public readonly kind = new QStringPath(this.withPrefix('kind'))
  public readonly meansOfTransportIn = new QStringPath(this.withPrefix('meansOfTransportIn'))
  public readonly meansOfTransportOut = new QStringPath(this.withPrefix('meansOfTransportOut'))
  public readonly locations = new QCollectionPath(
    this.withPrefix('locations'),
    () => QStringCollection
  )
  public readonly estimatedTimeArrival = new QDateTimeOffsetPath(
    this.withPrefix('estimatedTimeArrival')
  )
  public readonly numberOfOpenTasks = new QNumberPath(this.withPrefix('numberOfOpenTasks'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly shippingLine = new QStringPath(this.withPrefix('shippingLine'))
  public readonly vessel = new QStringPath(this.withPrefix('vessel'))
  public readonly returnAddress = new QStringPath(this.withPrefix('returnAddress'))
  public readonly alternativeReturnAddress = new QStringPath(
    this.withPrefix('alternativeReturnAddress')
  )
  public readonly returnReference = new QStringPath(this.withPrefix('returnReference'))
  public readonly alternativeReturnReference = new QStringPath(
    this.withPrefix('alternativeReturnReference')
  )
  public readonly detentionDateCustomerKtn = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerKtn')
  )
  public readonly detentionDateCustomerShippingLine = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerShippingLine')
  )
  public readonly closingOrDetentionDate = new QDateTimeOffsetPath(
    this.withPrefix('closingOrDetentionDate')
  )
  public readonly amsClosingDate = new QDateTimeOffsetPath(this.withPrefix('amsClosingDate'))
  public readonly vgmClosingDate = new QDateTimeOffsetPath(this.withPrefix('vgmClosingDate'))
  public readonly closingDate = new QDateTimeOffsetPath(this.withPrefix('closingDate'))
  public readonly freeFullInFromDate = new QDateTimeOffsetPath(
    this.withPrefix('freeFullInFromDate')
  )
  public readonly customerCode = new QStringPath(this.withPrefix('customerCode'))
  public readonly customerMainReference = new QStringPath(this.withPrefix('customerMainReference'))
  public readonly customerReferences = new QCollectionPath(
    this.withPrefix('customerReferences'),
    () => QStringCollection
  )
  public readonly operationalOrderId = new QStringPath(this.withPrefix('operationalOrderId'))
  public readonly operationalOrderParentId = new QStringPath(
    this.withPrefix('operationalOrderParentId')
  )
  public readonly operationalOrderType = new QStringPath(this.withPrefix('operationalOrderType'))
  public readonly loadingUnloadingPlannedAt = new QDateTimeOffsetPath(
    this.withPrefix('loadingUnloadingPlannedAt')
  )
  public readonly cargos = new QEntityCollectionPath(
    this.withPrefix('cargos'),
    () => QYardOrderCargoProjection
  )
  public readonly yardZone = new QStringPath(this.withPrefix('yardZone'))
  public readonly block = new QStringPath(this.withPrefix('block'))
  public readonly slots = new QCollectionPath(this.withPrefix('slots'), () => QStringCollection)
  public readonly driverWaits = new QBooleanPath(this.withPrefix('driverWaits'))
  public readonly isAvailableForPickup = new QBooleanPath(this.withPrefix('isAvailableForPickup'))
  public readonly isRejected = new QBooleanPath(this.withPrefix('isRejected'))
  public readonly rejectReason = new QStringPath(this.withPrefix('rejectReason'))
  public readonly isOnChassis = new QBooleanPath(this.withPrefix('isOnChassis'))
  public readonly isLiftOnOff = new QBooleanPath(this.withPrefix('isLiftOnOff'))
  public readonly parentOrderGroup = new QStringPath(this.withPrefix('parentOrderGroup'))
  public readonly isReused = new QBooleanPath(this.withPrefix('isReused'))
  public readonly isBlocked = new QBooleanPath(this.withPrefix('isBlocked'))
  public readonly actionBlocks = new QEntityCollectionPath(
    this.withPrefix('actionBlocks'),
    () => QActionBlockProjection
  )
  public readonly transportStatus = new QStringPath(this.withPrefix('transportStatus'))
  public readonly inAt = new QDateTimeOffsetPath(this.withPrefix('inAt'))
  public readonly incomingSeals = new QCollectionPath(
    this.withPrefix('incomingSeals'),
    () => QStringCollection
  )
  public readonly outgoingSeals = new QCollectionPath(
    this.withPrefix('outgoingSeals'),
    () => QStringCollection
  )
  public readonly hasIncomingSeals = new QBooleanPath(this.withPrefix('hasIncomingSeals'))
  public readonly hasOutgoingSeals = new QBooleanPath(this.withPrefix('hasOutgoingSeals'))
  public readonly operationalOrders = new QEntityCollectionPath(
    this.withPrefix('operationalOrders'),
    () => QOperationalOrderProjection
  )
}

export const qYardOrderProjection = new QYardOrderProjection()

export class QYardOrderProjectionId extends QId<YardOrderProjectionId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class QNotificationSubscriptionProjection extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly notificationType = new QStringPath(this.withPrefix('notificationType'))
  public readonly site = new QStringPath(this.withPrefix('site'))
  public readonly yardZone = new QStringPath(this.withPrefix('yardZone'))
  public readonly block = new QStringPath(this.withPrefix('block'))
  public readonly notificationRecipients = new QEntityCollectionPath(
    this.withPrefix('notificationRecipients'),
    () => QNotificationRecipientProjection
  )
  public readonly notificationConditions = new QEntityCollectionPath(
    this.withPrefix('notificationConditions'),
    () => QNotificationConditionProjection
  )
  public readonly createdAt = new QDateTimeOffsetPath(this.withPrefix('createdAt'))
}

export const qNotificationSubscriptionProjection = new QNotificationSubscriptionProjection()

export class QNotificationSubscriptionProjectionId extends QId<NotificationSubscriptionProjectionId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class QRuleProjection extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly triggers = new QEntityCollectionPath(
    this.withPrefix('triggers'),
    () => QTriggerProjection
  )
  public readonly siteConditions = new QEntityCollectionPath(
    this.withPrefix('siteConditions'),
    () => QRuleConditionProjection
  )
  public readonly shippingUnitLocationConditions = new QEntityCollectionPath(
    this.withPrefix('shippingUnitLocationConditions'),
    () => QRuleConditionProjection
  )
  public readonly actions = new QEntityCollectionPath(
    this.withPrefix('actions'),
    () => QActionProjection
  )
}

export const qRuleProjection = new QRuleProjection()

export class QRuleProjectionId extends QId<RuleProjectionId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataSiteDto extends QueryObject {
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly code = new QStringPath(this.withPrefix('code'))
  public readonly shortName = new QStringPath(this.withPrefix('shortName'))
  public readonly description = new QStringPath(this.withPrefix('description'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly longitude = new QNumberPath(this.withPrefix('longitude'))
  public readonly latitude = new QNumberPath(this.withPrefix('latitude'))
  public readonly address = new QEntityPath(this.withPrefix('address'), () => QODataAddressDto)
  public readonly id = new QGuidPath(this.withPrefix('id'))
}

export const qODataSiteDto = new QODataSiteDto()

export class QODataSiteDtoId extends QId<ODataSiteDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataYardZoneDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly yardZoneType = new QEnumPath(this.withPrefix('yardZoneType'))
  public readonly isLiftOnOff = new QBooleanPath(this.withPrefix('isLiftOnOff'))
  public readonly siteId = new QGuidPath(this.withPrefix('siteId'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly siteName = new QStringPath(this.withPrefix('siteName'))
  public readonly blocksCount = new QNumberPath(this.withPrefix('blocksCount'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
}

export const qODataYardZoneDto = new QODataYardZoneDto()

export class QODataYardZoneDtoId extends QId<ODataYardZoneDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataShippingUnitKindDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
  public readonly description = new QStringPath(this.withPrefix('description'))
  public readonly lengthInFeet = new QNumberPath(this.withPrefix('lengthInFeet'))
  public readonly type = new QEnumPath(this.withPrefix('type'))
}

export const qODataShippingUnitKindDto = new QODataShippingUnitKindDto()

export class QODataShippingUnitKindDtoId extends QId<ODataShippingUnitKindDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataBlockDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly prefix = new QStringPath(this.withPrefix('prefix'))
  public readonly slotType = new QEnumPath(this.withPrefix('slotType'))
  public readonly slotsCount = new QNumberPath(this.withPrefix('slotsCount'))
  public readonly yardZoneId = new QGuidPath(this.withPrefix('yardZoneId'))
  public readonly yardZoneName = new QStringPath(this.withPrefix('yardZoneName'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly isLiftOnOff = new QBooleanPath(this.withPrefix('isLiftOnOff'))
  public readonly isDefault = new QBooleanPath(this.withPrefix('isDefault'))
  public readonly yardZone = new QEntityPath(this.withPrefix('yardZone'), () => QODataYardZoneDto)
}

export const qODataBlockDto = new QODataBlockDto()

export class QODataBlockDtoId extends QId<ODataBlockDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataHandlerDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly assetNumber = new QStringPath(this.withPrefix('assetNumber'))
  public readonly type = new QEnumPath(this.withPrefix('type'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
}

export const qODataHandlerDto = new QODataHandlerDto()

export class QODataHandlerDtoId extends QId<ODataHandlerDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataOperatorDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly firstName = new QStringPath(this.withPrefix('firstName'))
  public readonly lastName = new QStringPath(this.withPrefix('lastName'))
  public readonly username = new QStringPath(this.withPrefix('username'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly siteCodes = new QCollectionPath(
    this.withPrefix('siteCodes'),
    () => QStringCollection
  )
}

export const qODataOperatorDto = new QODataOperatorDto()

export class QODataOperatorDtoId extends QId<ODataOperatorDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataSlotDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly row = new QNumberPath(this.withPrefix('row'))
  public readonly depth = new QNumberPath(this.withPrefix('depth'))
  public readonly level = new QNumberPath(this.withPrefix('level'))
  public readonly isLiftOnOff = new QBooleanPath(this.withPrefix('isLiftOnOff'))
  public readonly isLoadingDock = new QBooleanPath(this.withPrefix('isLoadingDock'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly isDefault = new QBooleanPath(this.withPrefix('isDefault'))
  public readonly slotType = new QEnumPath(this.withPrefix('slotType'))
  public readonly slotSize = new QEnumPath(this.withPrefix('slotSize'))
  public readonly blockId = new QGuidPath(this.withPrefix('blockId'))
  public readonly blockName = new QStringPath(this.withPrefix('blockName'))
  public readonly yardZoneId = new QGuidPath(this.withPrefix('yardZoneId'))
  public readonly yardZoneName = new QStringPath(this.withPrefix('yardZoneName'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
}

export const qODataSlotDto = new QODataSlotDto()

export class QODataSlotDtoId extends QId<ODataSlotDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataCustomerDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
}

export const qODataCustomerDto = new QODataCustomerDto()

export class QODataCustomerDtoId extends QId<ODataCustomerDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataShippingLineDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
}

export const qODataShippingLineDto = new QODataShippingLineDto()

export class QODataShippingLineDtoId extends QId<ODataShippingLineDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataTransportCompanyDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
}

export const qODataTransportCompanyDto = new QODataTransportCompanyDto()

export class QODataTransportCompanyDtoId extends QId<ODataTransportCompanyDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataYardVisitExternalIdDto extends QueryObject {
  public readonly externalIdentifier = new QStringPath(this.withPrefix('externalIdentifier'))
  public readonly yardVisitId = new QGuidPath(this.withPrefix('yardVisitId'))
  public readonly createdAt = new QDateTimeOffsetPath(this.withPrefix('createdAt'))
  public readonly createdBy = new QStringPath(this.withPrefix('createdBy'))
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly lastModifiedAt = new QDateTimeOffsetPath(this.withPrefix('lastModifiedAt'))
  public readonly lastModifiedBy = new QStringPath(this.withPrefix('lastModifiedBy'))
  public readonly rowVersion = new QBinaryPath(this.withPrefix('rowVersion'))
}

export const qODataYardVisitExternalIdDto = new QODataYardVisitExternalIdDto()

export class QODataYardVisitExternalIdDtoId extends QId<ODataYardVisitExternalIdDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataYardTaskFinishContextDto extends QueryObject {
  public readonly type = new QEntityPath(this.withPrefix('type'), () => QType)
  public readonly taskId = new QGuidPath(this.withPrefix('taskId'))
  public readonly yardVisitId = new QGuidPath(this.withPrefix('yardVisitId'))
  public readonly swappedYardVisitId = new QGuidPath(this.withPrefix('swappedYardVisitId'))
  public readonly createdAt = new QDateTimeOffsetPath(this.withPrefix('createdAt'))
  public readonly createdBy = new QStringPath(this.withPrefix('createdBy'))
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly lastModifiedAt = new QDateTimeOffsetPath(this.withPrefix('lastModifiedAt'))
  public readonly lastModifiedBy = new QStringPath(this.withPrefix('lastModifiedBy'))
  public readonly rowVersion = new QBinaryPath(this.withPrefix('rowVersion'))
}

export const qODataYardTaskFinishContextDto = new QODataYardTaskFinishContextDto()

export class QODataYardTaskFinishContextDtoId extends QId<ODataYardTaskFinishContextDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataOperationalSiteDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly code = new QStringPath(this.withPrefix('code'))
  public readonly freeSlotsCount = new QNumberPath(this.withPrefix('freeSlotsCount'))
  public readonly occupiedSlotsCount = new QNumberPath(this.withPrefix('occupiedSlotsCount'))
  public readonly totalSlotsCount = new QNumberPath(this.withPrefix('totalSlotsCount'))
  public readonly yardVisitsCount = new QNumberPath(this.withPrefix('yardVisitsCount'))
}

export const qODataOperationalSiteDto = new QODataOperationalSiteDto()

export class QODataOperationalSiteDtoId extends QId<ODataOperationalSiteDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataOperationalYardZoneDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly freeSlotsCount = new QNumberPath(this.withPrefix('freeSlotsCount'))
  public readonly occupiedSlotsCount = new QNumberPath(this.withPrefix('occupiedSlotsCount'))
  public readonly totalSlotsCount = new QNumberPath(this.withPrefix('totalSlotsCount'))
  public readonly yardVisitsCount = new QNumberPath(this.withPrefix('yardVisitsCount'))
}

export const qODataOperationalYardZoneDto = new QODataOperationalYardZoneDto()

export class QODataOperationalYardZoneDtoId extends QId<ODataOperationalYardZoneDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataOperationalBlockDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly yardZoneId = new QGuidPath(this.withPrefix('yardZoneId'))
  public readonly yardZoneName = new QStringPath(this.withPrefix('yardZoneName'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly totalSlotsCount = new QNumberPath(this.withPrefix('totalSlotsCount'))
  public readonly freeSlotsCount = new QNumberPath(this.withPrefix('freeSlotsCount'))
  public readonly occupiedSlotsCount = new QNumberPath(this.withPrefix('occupiedSlotsCount'))
  public readonly yardVisitsCount = new QNumberPath(this.withPrefix('yardVisitsCount'))
}

export const qODataOperationalBlockDto = new QODataOperationalBlockDto()

export class QODataOperationalBlockDtoId extends QId<ODataOperationalBlockDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataOperationalSlotDto extends QueryObject {
  public readonly id = new QGuidPath(this.withPrefix('id'))
  public readonly siteCode = new QStringPath(this.withPrefix('siteCode'))
  public readonly name = new QStringPath(this.withPrefix('name'))
  public readonly row = new QNumberPath(this.withPrefix('row'))
  public readonly depth = new QNumberPath(this.withPrefix('depth'))
  public readonly level = new QNumberPath(this.withPrefix('level'))
  public readonly isLiftOnOff = new QBooleanPath(this.withPrefix('isLiftOnOff'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly slotType = new QEnumPath(this.withPrefix('slotType'))
  public readonly slotSize = new QEnumPath(this.withPrefix('slotSize'))
  public readonly slotStatus = new QEnumPath(this.withPrefix('slotStatus'))
  public readonly blockId = new QGuidPath(this.withPrefix('blockId'))
  public readonly blockName = new QStringPath(this.withPrefix('blockName'))
  public readonly yardZoneId = new QGuidPath(this.withPrefix('yardZoneId'))
  public readonly yardZoneName = new QStringPath(this.withPrefix('yardZoneName'))
  public readonly yardVisits = new QEntityCollectionPath(
    this.withPrefix('yardVisits'),
    () => QODataSlotYardVisitDto
  )
}

export const qODataOperationalSlotDto = new QODataOperationalSlotDto()

export class QODataOperationalSlotDtoId extends QId<ODataOperationalSlotDtoId> {
  private readonly params = [new QGuidParam('id')]

  getParams() {
    return this.params
  }
}

export class QODataSwapEligibleContainerDto extends QueryObject {
  public readonly yardOrderId = new QStringPath(this.withPrefix('yardOrderId'))
  public readonly number = new QStringPath(this.withPrefix('number'))
  public readonly locations = new QCollectionPath(
    this.withPrefix('locations'),
    () => QStringCollection
  )
  public readonly locationDetails = new QEntityPath(
    this.withPrefix('locationDetails'),
    () => QODataShippingUnitLocationDto
  )
  public readonly detentionDateCustomerKtn = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerKtn')
  )
  public readonly realClosingDate = new QDateTimeOffsetPath(this.withPrefix('realClosingDate'))
  public readonly kind = new QStringPath(this.withPrefix('kind'))
  public readonly adrClasses = new QCollectionPath(
    this.withPrefix('adrClasses'),
    () => QStringCollection
  )
  public readonly ghsClasses = new QCollectionPath(
    this.withPrefix('ghsClasses'),
    () => QStringCollection
  )
  public readonly lastMoveExecutedAt = new QDateTimeOffsetPath(
    this.withPrefix('lastMoveExecutedAt')
  )
}

export const qODataSwapEligibleContainerDto = new QODataSwapEligibleContainerDto()

export class QODataSwapEligibleContainerDtoId extends QId<ODataSwapEligibleContainerDtoId> {
  private readonly params = [new QStringParam('yardOrderId')]

  getParams() {
    return this.params
  }
}

export class QOperationalOrderProjection extends QueryObject {
  public readonly id = new QStringPath(this.withPrefix('id'))
  public readonly customerMainReference = new QStringPath(this.withPrefix('customerMainReference'))
  public readonly customerReferences = new QCollectionPath(
    this.withPrefix('customerReferences'),
    () => QStringCollection
  )
  public readonly operationalOrderId = new QStringPath(this.withPrefix('operationalOrderId'))
  public readonly operationalOrderParentId = new QStringPath(
    this.withPrefix('operationalOrderParentId')
  )
  public readonly loadedUnloadedAt = new QDateTimeOffsetPath(this.withPrefix('loadedUnloadedAt'))
  public readonly isActive = new QBooleanPath(this.withPrefix('isActive'))
  public readonly owner = new QStringPath(this.withPrefix('owner'))
  public readonly blockedProcesses = new QEntityCollectionPath(
    this.withPrefix('blockedProcesses'),
    () => QBlockedProcessProjection
  )
}

export const qOperationalOrderProjection = new QOperationalOrderProjection()

export class QOperationalOrderProjectionId extends QId<OperationalOrderProjectionId> {
  private readonly params = [new QStringParam('id')]

  getParams() {
    return this.params
  }
}

export class QShippingUnitLocationProjection extends QueryObject {
  public readonly yardZone = new QStringPath(this.withPrefix('yardZone'))
  public readonly block = new QStringPath(this.withPrefix('block'))
  public readonly slots = new QCollectionPath(this.withPrefix('slots'), () => QStringCollection)
}

export const qShippingUnitLocationProjection = new QShippingUnitLocationProjection()

export class QYardLocationProjection extends QueryObject {
  public readonly yardZone = new QStringPath(this.withPrefix('yardZone'))
  public readonly block = new QStringPath(this.withPrefix('block'))
  public readonly slot = new QStringPath(this.withPrefix('slot'))
}

export const qYardLocationProjection = new QYardLocationProjection()

export class QYardTaskCargoProjection extends QueryObject {
  public readonly article = new QStringPath(this.withPrefix('article'))
  public readonly product = new QStringPath(this.withPrefix('product'))
  public readonly lotBatch = new QStringPath(this.withPrefix('lotBatch'))
  public readonly configuration = new QStringPath(this.withPrefix('configuration'))
  public readonly warehouseCode = new QStringPath(this.withPrefix('warehouseCode'))
  public readonly gateCode = new QStringPath(this.withPrefix('gateCode'))
  public readonly adrClasses = new QCollectionPath(
    this.withPrefix('adrClasses'),
    () => QStringCollection
  )
  public readonly ghsClasses = new QCollectionPath(
    this.withPrefix('ghsClasses'),
    () => QStringCollection
  )
}

export const qYardTaskCargoProjection = new QYardTaskCargoProjection()

export class QProcessingErrorProjection extends QueryObject {
  public readonly errorMessage = new QStringPath(this.withPrefix('errorMessage'))
  public readonly processName = new QStringPath(this.withPrefix('processName'))
  public readonly parentProcessName = new QStringPath(this.withPrefix('parentProcessName'))
  public readonly timestamp = new QDateTimeOffsetPath(this.withPrefix('timestamp'))
}

export const qProcessingErrorProjection = new QProcessingErrorProjection()

export class QYardOrderCargoProjection extends QueryObject {
  public readonly article = new QStringPath(this.withPrefix('article'))
  public readonly product = new QStringPath(this.withPrefix('product'))
  public readonly lotBatch = new QStringPath(this.withPrefix('lotBatch'))
  public readonly configuration = new QStringPath(this.withPrefix('configuration'))
  public readonly warehouseCode = new QStringPath(this.withPrefix('warehouseCode'))
  public readonly gateCode = new QStringPath(this.withPrefix('gateCode'))
  public readonly adrClasses = new QCollectionPath(
    this.withPrefix('adrClasses'),
    () => QStringCollection
  )
  public readonly ghsClasses = new QCollectionPath(
    this.withPrefix('ghsClasses'),
    () => QStringCollection
  )
}

export const qYardOrderCargoProjection = new QYardOrderCargoProjection()

export class QBlockedProcessProjection extends QueryObject {
  public readonly processName = new QStringPath(this.withPrefix('processName'))
  public readonly conditions = new QEntityCollectionPath(
    this.withPrefix('conditions'),
    () => QConditionProjection
  )
}

export const qBlockedProcessProjection = new QBlockedProcessProjection()

export class QConditionProjection extends QueryObject {
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly values = new QCollectionPath(this.withPrefix('values'), () => QStringCollection)
}

export const qConditionProjection = new QConditionProjection()

export class QActionBlockProjection extends QueryObject {
  public readonly blockMovements = new QBooleanPath(this.withPrefix('blockMovements'))
  public readonly blockCheckOut = new QBooleanPath(this.withPrefix('blockCheckOut'))
  public readonly reason = new QStringPath(this.withPrefix('reason'))
  public readonly blockedBy = new QStringPath(this.withPrefix('blockedBy'))
}

export const qActionBlockProjection = new QActionBlockProjection()

export class QNotificationRecipientProjection extends QueryObject {
  public readonly recipientType = new QStringPath(this.withPrefix('recipientType'))
  public readonly value = new QStringPath(this.withPrefix('value'))
}

export const qNotificationRecipientProjection = new QNotificationRecipientProjection()

export class QNotificationConditionProjection extends QueryObject {
  public readonly conditionType = new QStringPath(this.withPrefix('conditionType'))
  public readonly values = new QCollectionPath(this.withPrefix('values'), () => QStringCollection)
}

export const qNotificationConditionProjection = new QNotificationConditionProjection()

export class QTriggerProjection extends QueryObject {
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly value = new QStringPath(this.withPrefix('value'))
}

export const qTriggerProjection = new QTriggerProjection()

export class QRuleConditionProjection extends QueryObject {
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly values = new QCollectionPath(this.withPrefix('values'), () => QStringCollection)
}

export const qRuleConditionProjection = new QRuleConditionProjection()

export class QActionProjection extends QueryObject {
  public readonly type = new QStringPath(this.withPrefix('type'))
  public readonly value = new QStringPath(this.withPrefix('value'))
}

export const qActionProjection = new QActionProjection()

export class QODataAddressDto extends QueryObject {
  public readonly streetAndNumber = new QStringPath(this.withPrefix('streetAndNumber'))
  public readonly box = new QStringPath(this.withPrefix('box'))
  public readonly building = new QStringPath(this.withPrefix('building'))
  public readonly city = new QStringPath(this.withPrefix('city'))
  public readonly zip = new QStringPath(this.withPrefix('zip'))
  public readonly country = new QStringPath(this.withPrefix('country'))
}

export const qODataAddressDto = new QODataAddressDto()

export class QMemberInfo extends QueryObject {
  public readonly customAttributes = new QEntityCollectionPath(
    this.withPrefix('customAttributes'),
    () => QCustomAttributeData
  )
}

export const qMemberInfo = new QMemberInfo()

export class QType extends QMemberInfo {
  public readonly genericTypeArguments = new QEntityCollectionPath(
    this.withPrefix('genericTypeArguments'),
    () => QType
  )
}

export const qType = new QType()

export class QCustomAttributeData extends QueryObject {
  public readonly constructorArguments = new QEntityCollectionPath(
    this.withPrefix('constructorArguments'),
    () => QCustomAttributeTypedArgument
  )
  public readonly namedArguments = new QEntityCollectionPath(
    this.withPrefix('namedArguments'),
    () => QCustomAttributeNamedArgument
  )
}

export const qCustomAttributeData = new QCustomAttributeData()

export class QCustomAttributeTypedArgument extends QueryObject {}

export const qCustomAttributeTypedArgument = new QCustomAttributeTypedArgument()

export class QCustomAttributeNamedArgument extends QueryObject {}

export const qCustomAttributeNamedArgument = new QCustomAttributeNamedArgument()

export class QTypeInfo extends QType {
  public readonly genericTypeParameters = new QEntityCollectionPath(
    this.withPrefix('genericTypeParameters'),
    () => QType
  )
  public readonly declaredConstructors = new QEntityCollectionPath(
    this.withPrefix('declaredConstructors'),
    () => QConstructorInfo
  )
  public readonly declaredEvents = new QEntityCollectionPath(
    this.withPrefix('declaredEvents'),
    () => QEventInfo
  )
  public readonly declaredFields = new QEntityCollectionPath(
    this.withPrefix('declaredFields'),
    () => QFieldInfo
  )
  public readonly declaredMembers = new QEntityCollectionPath(
    this.withPrefix('declaredMembers'),
    () => QMemberInfo
  )
  public readonly declaredMethods = new QEntityCollectionPath(
    this.withPrefix('declaredMethods'),
    () => QMethodInfo
  )
  public readonly declaredNestedTypes = new QEntityCollectionPath(
    this.withPrefix('declaredNestedTypes'),
    () => QTypeInfo
  )
  public readonly declaredProperties = new QEntityCollectionPath(
    this.withPrefix('declaredProperties'),
    () => QPropertyInfo
  )
  public readonly implementedInterfaces = new QEntityCollectionPath(
    this.withPrefix('implementedInterfaces'),
    () => QType
  )
}

export const qTypeInfo = new QTypeInfo()

export class QGenericTypeParameterBuilder extends QTypeInfo {}

export const qGenericTypeParameterBuilder = new QGenericTypeParameterBuilder()

export class QMethodBase extends QMemberInfo {}

export const qMethodBase = new QMethodBase()

export class QConstructorInfo extends QMethodBase {}

export const qConstructorInfo = new QConstructorInfo()

export class QConstructorBuilder extends QConstructorInfo {
  public readonly initLocals = new QBooleanPath(this.withPrefix('initLocals'))
}

export const qConstructorBuilder = new QConstructorBuilder()

export class QEventInfo extends QMemberInfo {}

export const qEventInfo = new QEventInfo()

export class QComAwareEventInfo extends QEventInfo {}

export const qComAwareEventInfo = new QComAwareEventInfo()

export class QFieldInfo extends QMemberInfo {}

export const qFieldInfo = new QFieldInfo()

export class QFieldBuilder extends QFieldInfo {}

export const qFieldBuilder = new QFieldBuilder()

export class QPropertyInfo extends QMemberInfo {}

export const qPropertyInfo = new QPropertyInfo()

export class QPropertyBuilder extends QPropertyInfo {}

export const qPropertyBuilder = new QPropertyBuilder()

export class QMethodInfo extends QMethodBase {}

export const qMethodInfo = new QMethodInfo()

export class QMethodBuilder extends QMethodInfo {
  public readonly initLocals = new QBooleanPath(this.withPrefix('initLocals'))
}

export const qMethodBuilder = new QMethodBuilder()

export class QDynamicMethod extends QMethodInfo {
  public readonly initLocals = new QBooleanPath(this.withPrefix('initLocals'))
}

export const qDynamicMethod = new QDynamicMethod()

export class QEnumBuilder extends QTypeInfo {}

export const qEnumBuilder = new QEnumBuilder()

export class QTypeBuilder extends QTypeInfo {}

export const qTypeBuilder = new QTypeBuilder()

export class QTypeDelegator extends QTypeInfo {}

export const qTypeDelegator = new QTypeDelegator()

export class QODataSlotYardVisitDto extends QueryObject {
  public readonly yardVisitId = new QGuidPath(this.withPrefix('yardVisitId'))
  public readonly loadingUnloadingPlannedAt = new QDateTimeOffsetPath(
    this.withPrefix('loadingUnloadingPlannedAt')
  )
  public readonly loadedUnloadedAt = new QDateTimeOffsetPath(this.withPrefix('loadedUnloadedAt'))
  public readonly detentionDateCustomerKtn = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerKtn')
  )
  public readonly detentionDateCustomerShippingLine = new QDateTimeOffsetPath(
    this.withPrefix('detentionDateCustomerShippingLine')
  )
  public readonly closingDate = new QDateTimeOffsetPath(this.withPrefix('closingDate'))
  public readonly shippingUnitNumber = new QStringPath(this.withPrefix('shippingUnitNumber'))
  public readonly shippingUnitKind = new QStringPath(this.withPrefix('shippingUnitKind'))
  public readonly orderId = new QStringPath(this.withPrefix('orderId'))
  public readonly pickUpAddress = new QStringPath(this.withPrefix('pickUpAddress'))
  public readonly isOnChassis = new QBooleanPath(this.withPrefix('isOnChassis'))
  public readonly orderType = new QEnumPath(this.withPrefix('orderType'))
  public readonly hasQueuedMoves = new QBooleanPath(this.withPrefix('hasQueuedMoves'))
}

export const qODataSlotYardVisitDto = new QODataSlotYardVisitDto()

export class QODataShippingUnitLocationDto extends QueryObject {
  public readonly yardZone = new QStringPath(this.withPrefix('yardZone'))
  public readonly block = new QStringPath(this.withPrefix('block'))
  public readonly slots = new QCollectionPath(this.withPrefix('slots'), () => QStringCollection)
}

export const qODataShippingUnitLocationDto = new QODataShippingUnitLocationDto()
