import { useSuspenseQuery } from '@tanstack/react-query'
import { type YardTaskProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'

import { client } from '@mobile/api/configuration'

export const yardTaskDetails = {
  queryKey: (id: string) => ['yard-task', { entity: id }],
  queryFn: async ({ signal, id }: { signal?: AbortSignal; id: string }) => {
    const response = await client.request<YardTaskProjection>({
      path: `/indexes/yard-tasks/docs('${id}')`,
      secure: true,
      query: { 'api-version': '2023-10-01-Preview' },
      format: 'json',
      signal
    })

    return response.data
  }
}

export const useYardTaskDetailsQuery = (id: string, initialData?: YardTaskProjection) => {
  const query = useSuspenseQuery({
    initialData,
    queryKey: yardTaskDetails.queryKey(id),
    queryFn: ({ signal }) => yardTaskDetails.queryFn({ signal, id })
  })

  return query
}
