import { type SortingState, type PaginationState } from '@tanstack/react-table'
import { type Filtering, type UserContext } from '@yms/common/components/Table/Table.types'

export const getTableQueryKey = <TUserContext = UserContext>({
  id,
  userContext,
  entity,
  pagination,
  search,
  sorting,
  filtering
}: {
  id: string
  userContext: TUserContext
  entity: string
  pagination?: PaginationState
  search?: string
  sorting?: SortingState
  filtering: Filtering
}) => [entity, 'table', userContext, { id, pagination, search, sorting, filtering }]
