import { Link, matchPath, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { Badge, Icon, Text } from '@yms/hexagon'

import styles from './Menu.module.css'

type MenuItemProps = {
  readonly icon: string
  readonly title: string
  readonly path: string
  readonly badge?: number
}

export function MenuItem({ path, icon, title, badge }: MenuItemProps) {
  const { pathname } = useLocation()
  const active = !!matchPath({ path, end: false }, pathname)

  return (
    <Link
      to={path}
      data-testid={path}
      viewTransition
      className={clsx(styles.item, { [styles.itemActive]: active })}
    >
      <div className={styles.itemContent}>
        {badge ? (
          <Badge limit={9999} value={badge}>
            <Icon name={icon} size="lg" />
          </Badge>
        ) : (
          <Icon name={icon} size="lg" />
        )}
        <Text variant="body" bold>
          {title}
        </Text>
      </div>
    </Link>
  )
}
