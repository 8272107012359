import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Icon, type ButtonProps } from '@yms/hexagon'
import { useTranslate } from '@yms/common/hooks/useTranslate'
import { useIsOffline } from '@yms/common/components/Offline/useIsOffline'

import { prefetch } from '@mobile/prefetch'

import { userManager } from '../userManager'

type LogoutButton = {
  readonly variant?: ButtonProps['variant']
}

export const LogoutButton = ({ variant }: LogoutButton) => {
  const queryClient = useQueryClient()
  const isOffline = useIsOffline()
  const t = useTranslate()

  const [loading, setLoading] = useState(false)

  return (
    <Button
      size="lg"
      variant={variant}
      disabled={isOffline}
      data-testid="logout"
      loading={loading}
      onClick={() => {
        setLoading(true)
        prefetch.stop()
        queryClient.cancelQueries()
        queryClient.clear()
        userManager.signoutRedirect().finally(() => {
          setLoading(false)
        })
      }}
    >
      <Icon slot="leading" size="lg" name="logout" />
      {t('logout')}
    </Button>
  )
}
