import { ErrorPage } from './core/ErrorPage/ErrorPage'
import styles from './App.ErrorElement.module.css'
import { LogoutButton } from './auth/components/LogoutButton'

export function ForbiddenErrorPage() {
  return (
    <div className={styles.main}>
      <ErrorPage title="forbidden" subtitle="forbiddenMessage">
        <LogoutButton variant="primary" data-testid="logout" />
      </ErrorPage>
    </div>
  )
}
