import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { type UserContext, type TableView, type FetcherParameters } from '../Table.types'
import { getTableQueryKey } from '../utils/getTableQueryKey'

interface UseGetCountQueryOptions<TUserContext = UserContext> {
  readonly fetcher?: (params: FetcherParameters<TUserContext>) => Promise<number>
  readonly tableName: string
  readonly state: TableView
  readonly userContext: TUserContext
  readonly enabled?: boolean
}

export function useGetCountQuery<TUserContext = UserContext>({
  fetcher,
  tableName,
  state,
  userContext,
  enabled = true
}: UseGetCountQueryOptions<TUserContext>) {
  return useQuery({
    enabled: enabled && !!fetcher,
    placeholderData: keepPreviousData,
    queryKey: [
      ...getTableQueryKey({
        id: state.id,
        userContext,
        search: '',
        entity: tableName,
        filtering: state.filtering
      }),
      'get-count'
    ],
    queryFn: async ({ signal, queryKey }) => {
      const result = await fetcher?.({
        signal,
        queryKey,
        search: '',
        userContext,
        filtering: state.filtering
      })

      return result ?? 0
    }
  })
}
