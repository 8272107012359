import { get, has, isObject } from 'lodash-es'

import { type TableSettings } from '../Table.types'

export const isTableSettings = (value: unknown): value is TableSettings => {
  if (
    isObject(value) &&
    has(value, 'views') &&
    has(value, 'selectedView') &&
    Array.isArray(get(value, 'views'))
  ) {
    return true
  }

  return false
}
