import { get as _get, isFunction } from 'lodash-es'
import { type Persister, type PersistedClient } from '@tanstack/react-query-persist-client'
import {
  type InvalidateQueryFilters,
  MutationCache,
  QueryCache,
  QueryClient,
  type QueryFilters
} from '@tanstack/react-query'
import { get, del, set, createStore } from 'idb-keyval'
import { error as logError } from '@yms/common/logging'

const key = 'query-store'
const queryStore = createStore('react-query', key)

export type AffectedQuery = InvalidateQueryFilters & { timeout?: number }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OptimisticUpdate = QueryFilters & { updater: any }

export type MutationContext = {
  affectedQueries?: AffectedQuery[]
  shouldUpdateQueries?: boolean
  optimisticUpdates?: OptimisticUpdate[]
}

let inMemoryClient: PersistedClient = {
  buster: '',
  timestamp: 0,
  clientState: {
    mutations: [],
    queries: []
  }
}

export const persister: Persister = {
  persistClient: (client: PersistedClient) => {
    inMemoryClient = client
  },
  restoreClient: async () => (await get(key, queryStore)) ?? ({} as PersistedClient),
  removeClient: () => del(key, queryStore)
}

setInterval(() => {
  set(key, inMemoryClient, queryStore)
}, 160)

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: (_result, variables, _context, mutation) => {
      const onSuccessEffect = _get(mutation.meta, 'onSuccessEffect')
      if (!isFunction(onSuccessEffect)) return

      const effectContext = onSuccessEffect(variables) as MutationContext
      if (!effectContext.shouldUpdateQueries) return
      const optimisticUpdates = _get(effectContext, 'optimisticUpdates', [])
      const affectedQueries = _get(effectContext, 'affectedQueries', [])

      for (const update of optimisticUpdates) {
        queryClient.setQueriesData(update, update.updater)
      }

      for (const query of affectedQueries) {
        setTimeout(() => {
          queryClient.invalidateQueries(query)
        }, query.timeout)
      }
    }
  }),
  queryCache: new QueryCache({
    onError: error => {
      if (error instanceof Response) {
        if (error.status === 500) {
          logError('500', error)
        }
      }
    }
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof Response) {
          // on not found there's no reason to do retry
          if (error.status === 404) {
            return false
          }

          if (error.status >= 500 && error.status !== 503) {
            return false
          }
        }

        if (failureCount < 5) {
          return true
        }

        return false
      },
      refetchOnWindowFocus: query => {
        const error = query.state.fetchFailureReason
        if (error instanceof Response) {
          // bail early for 5xx, nothing will change soon with it anyways
          if (error.status >= 500 && error.status !== 503) {
            return false
          }

          // on not found there's no reason to do retry
          if (error.status === 404) {
            return false
          }
        }

        return true
      },
      retryDelay: failureCount => {
        return 5000 + failureCount * 2000
      },
      staleTime: 30_000, // time in ms,
      refetchInterval: 180_000
    }
  }
})
