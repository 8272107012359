import { createBrowserRouter, redirect } from 'react-router-dom'
import { delMany } from 'idb-keyval'
import { loaderWithGuards } from '@yms/common/auth'
import { getIsOffline } from '@yms/common/components/Offline/getIsOffline'

import { Layout } from './core/Layout/Layout'
import { setupModuleRouter } from './modules/setup'
import { LayoutErrorPage } from './core/Layout/Layout.errorPage'
import { loginModuleRouter } from './modules/login'
import { userManager } from './auth/userManager'
import { Login } from './auth/views/Login'
import { NotFoundErrorPage } from './core/ErrorPage'
import { mainModuleRouter } from './modules/main'
import { ErrorElement } from './App.ErrorElement'
import { initialSetupModuleRouter } from './modules/initial-setup'
import { contextGuard, initialSetupGuard, authGuard } from './auth/guards'
import { prefetchWorker } from './prefetch/client/prefetch'
import { ForbiddenErrorPage } from './App.ForbiddenErrorPage'

export const router = createBrowserRouter([
  ...initialSetupModuleRouter,
  {
    path: 'auth',
    loader: loaderWithGuards([initialSetupGuard], async () => {
      const user = await userManager.getUser()
      if (user) {
        return redirect('/')
      }
      return null
    }),
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'sign-in',
        loader: async () => {
          try {
            await userManager.signinRedirectCallback()
            await delMany(['handlerId', 'handlerType', 'yardZoneIds'])
            return redirect('/login/handler-selection')
          } catch {
            return redirect('/auth/login')
          }
        }
      },
      {
        path: 'logout',
        loader: async () => {
          await userManager.signoutRedirectCallback()
          return redirect('/')
        }
      },
      { path: '', loader: () => redirect('/auth/login') },
      { path: '*', loader: () => redirect('/auth/login') }
    ]
  },
  {
    path: 'login',
    errorElement: <ErrorElement />,
    loader: loaderWithGuards([initialSetupGuard, authGuard]),
    children: loginModuleRouter
  },
  {
    path: '403',
    errorElement: <ErrorElement />,
    element: <ForbiddenErrorPage />
  },
  {
    path: '*',
    errorElement: <ErrorElement />,
    loader: loaderWithGuards([initialSetupGuard, authGuard, contextGuard], async () => {
      if (!getIsOffline()) {
        prefetchWorker.postMessage({ type: 'fetchTable' })
      }

      return null
    }),
    children: [
      {
        path: '*',
        element: <Layout />,
        errorElement: <LayoutErrorPage />,
        children: [
          ...setupModuleRouter,
          ...mainModuleRouter,
          { path: '', loader: () => redirect('/tasks') },
          { path: '*', element: <NotFoundErrorPage /> }
        ]
      }
    ]
  }
])
